/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Accordion} from "react-bootstrap";
import QuickSummary from "../../../src/components/quick-summary";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    img: "img",
    em: "em",
    span: "span",
    h3: "h3",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  if (!Accordion) _missingMdxReference("Accordion", false);
  if (!Accordion.Body) _missingMdxReference("Accordion.Body", true);
  if (!Accordion.Header) _missingMdxReference("Accordion.Header", true);
  if (!Accordion.Item) _missingMdxReference("Accordion.Item", true);
  return React.createElement(React.Fragment, null, React.createElement(QuickSummary, {
    description: "<p>If you are a traditional taxi dispatch business owner providing various services like airport transfer, corporate transfer, ride-hailing or solely dispatch services, then this blog is for you. In this blog, you will discover today’s profit-driving strategies that successful taxi businesses use for their growth.</p>"
  }), "\n", React.createElement(_components.p, null, "Taxis are the same. The taxi business is the same. What has changed is the way of offering service. It is not that taxis have got wings and are flying on the road picking the passengers."), "\n", React.createElement(_components.p, null, "Just an upgrade of the taxi businesses from offline to online using the ", React.createElement(_components.a, {
    href: "/taxi-dispatch-software/"
  }, "taxi dispatch software"), " has changed the entire taxi industry."), "\n", React.createElement(_components.p, null, "As per ", React.createElement(_components.a, {
    href: "https://www.businessresearchinsights.com/market-reports/taxi-dispatch-software-market-106601",
    target: "_blank",
    rel: "nofollow"
  }, "the study"), ", in 2020, the global taxi dispatch software market size was valued at 332 million. And it is expected to reach $1961.4 million by 2031 at a CAGR of 17.5% during the forecast period."), "\n", React.createElement(_components.p, null, "It means that the technology is evolving and taxi businesses are embracing it. The other strong message it gives is - that taking your taxi business online is not just an upgrade or choice but is a need now."), "\n", React.createElement(_components.p, null, "Your bread and butter (your customers) prefer booking taxis online and if you are not online, you will lose customers, and ultimately the profit."), "\n", React.createElement(_components.p, null, "If you are running your taxi business manually, this blog can change your life. Also, if you are using software, this blog is for you too as it covers some winning strategies that your competitors and giant taxi businesses like Uber and Lyft use."), "\n", React.createElement(_components.p, null, "By the end of this blog, you will discover all the aspects of a profit-driven strategy which you can apply to make your traditional taxi dispatch business profitable."), "\n", React.createElement(_components.p, null, "Now without any more addons, let’s start discovering the strategy."), "\n", React.createElement(_components.h2, {
    id: "go-online-from-offline"
  }, "Go online from offline"), "\n", React.createElement(_components.p, null, "Transform your traditional business into a modern one. Why? Because, you see big names like Uber, Ola, Lyft and more - they are all running their business online. They are eating up all your customer base, booking, and profit."), "\n", React.createElement(_components.img, {
    src: "/68a88157d2889c075eec20adacd6a87f/go-online-from-offline-yelowsoft.gif",
    alt: "go-online-from-offline-yelowsoft"
  }), "\n", React.createElement(_components.p, null, "It ultimately is damaging your business every single day, hour, and minute. And if you just keep watching them adopting the demand of today and the future (that is modernizing business by building their own ", React.createElement(_components.a, {
    href: "/blog/how-taxi-booking-app-reduces-the-operational-cost/"
  }, "taxi booking app"), " or taxi dispatch software) the day is not far when you will be thrown out of the market. And people won’t even remember your name."), "\n", React.createElement(_components.p, null, "See this out:"), "\n", React.createElement(_components.p, null, "You may be in the taxi business before 2010 or after 2010. Uber stepped into the taxi industry in 2010."), "\n", React.createElement(_components.p, null, "Have an Uber overview:"), "\n", React.createElement("div", {
    className: "blog-table table-responsive"
  }, React.createElement("table", {
    className: "table"
  }, React.createElement("tbody", null, React.createElement("tr", null, React.createElement("td", null, React.createElement(_components.em, null, "Launch date")), React.createElement("td", null, "May 2010HQ")), React.createElement("tr", null, React.createElement("td", null, React.createElement(_components.em, null, "HQ")), React.createElement("td", null, " San Francisco, California ")), React.createElement("tr", null, React.createElement("td", null, React.createElement(_components.em, null, "People")), React.createElement("td", null, " Dara Khosrowshahi (CEO), Travis Kalanick (co-founder), Garrett Camp (co-founder) ")), React.createElement("tr", null, React.createElement("td", null, React.createElement(_components.em, null, "Business type")), React.createElement("td", null, " Public (NYSE: UBER)")), React.createElement("tr", null, React.createElement("td", null, React.createElement(_components.em, null, "Industry")), React.createElement("td", null, " Ride-hailing"))))), "\n", React.createElement(_components.p, null, "Moreover, let me give you an example of Uber’s quarterly revenue overview from 2017 to 2023 ($mm)."), "\n", React.createElement(_components.p, null, "Uber made $31.8 billion in revenue in 2022 and it is expected to generate even more revenue in the coming years. It may make a new record every year by offering service online and the other strategies you will discover below."), "\n", React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 75.99999999999999%; position: relative; bottom: 0; left: 0; background-image: url('data:image/webp;base64,UklGRnAAAABXRUJQVlA4IGQAAAAwBACdASoUAA8APtFUo0uoJKMhsAgBABoJZACdMoMwtEmgR4bAY18BNxAA/vHpl5iH3J7Qol1QZ12LfM6mBsIHYbSlTz/Iv2l81IoDHfJtOKSD27C5O0VK3DifQMii9j+miAAA'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"facts\"\n        title=\"\"\n        data-src=\"/static/e25be2993eef931a72ff8ef42ac6326a/cf465/facts.webp\"\n        data-srcset=\"/static/e25be2993eef931a72ff8ef42ac6326a/a5e6d/facts.webp 200w,\n/static/e25be2993eef931a72ff8ef42ac6326a/2276a/facts.webp 400w,\n/static/e25be2993eef931a72ff8ef42ac6326a/cf465/facts.webp 800w,\n/static/e25be2993eef931a72ff8ef42ac6326a/cbd37/facts.webp 1200w,\n/static/e25be2993eef931a72ff8ef42ac6326a/64296/facts.webp 1600w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }), "\n", React.createElement(_components.p, null, "See yourself, where do you stand? Ask yourself what is that you cannot do and what Uber is doing."), "\n", React.createElement(_components.p, null, "If you ask Yelowsoft, we’ll say, you can do all that Uber is doing. You can even compete with it in all areas from brand positioning to driving massive revenue."), "\n", React.createElement(_components.p, null, "Now the thing is, is only going online and offering taxi services helping this giant become an industry leader and drive this massive revenue? Well, No!"), "\n", React.createElement(_components.p, null, "Here are the 4 solutions every successful online taxi dispatch service provider uses. These 4 solutions are what make a complete ", React.createElement(_components.a, {
    href: "/taxi-dispatch-software/"
  }, "taxi cab dispatch software"), " that these brands use to beat local taxi dispatchers like you."), "\n", React.createElement(_components.p, null, "And you can use it too. Earlier it used to be expensive to upgrade offline business to online. But not now! With the help of Yelowsoft, you can take your business online in just days and launch your branded taxi booking app in just 2 to 3 weeks."), "\n", React.createElement(_components.p, null, "Coming back to the point, let us now discover the 4 solutions one by one."), "\n", React.createElement(_components.h3, null, "Admin panel"), "\n", React.createElement(_components.p, null, "This is the central dashboard for fleet managers like you to coordinate and manage everything from a single screen. It also helps you get real-time insights."), "\n", React.createElement(_components.h3, null, "Customer app"), "\n", React.createElement(_components.p, null, "A smartphone app your customers can use to book, track and pay for taxi rides on demand from their phones - just like Uber."), "\n", React.createElement(_components.h3, null, "Driver app"), "\n", React.createElement(_components.p, null, "The mobile app taxi drivers use to receive and fulfill ride requests, navigation assistance, billing/payments and more all via their smartphone."), "\n", React.createElement(_components.h3, null, "Vendor/Dispatch panel/app"), "\n", React.createElement(_components.p, null, "The centralized control system helps your dispatcher manage all aspects of your taxi business - from assigning rides to drivers to monitoring real-time traffic updates."), "\n", React.createElement(_components.h2, {
    id: "buy-a-ready-to-use-software-with-these-features"
  }, "Buy a ready to use software with these features"), "\n", React.createElement(_components.p, null, "Uber, Lyft, and more are the big names you know who provide taxi dispatching services online using taxi booking app. You may also be thinking that these are big companies with strong investors backing them."), "\n", React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 67.5%; position: relative; bottom: 0; left: 0; background-image: url('data:image/webp;base64,UklGRloAAABXRUJQVlA4IE4AAABQAwCdASoUAA4APtFUo0uoJKMhsAgBABoJZwAAW+gTc43BAAD+6ux4fNoG3ef6oOYEi0ImKnPgUhLc6DS3zu7qnfG0VeuL7ZDQWml7jgA='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"software-with-these-features-yelowsoft\"\n        title=\"\"\n        data-src=\"/static/70e4a7dbac16d962abb143ed15148892/cf465/software-with-these-features-yelowsoft.webp\"\n        data-srcset=\"/static/70e4a7dbac16d962abb143ed15148892/a5e6d/software-with-these-features-yelowsoft.webp 200w,\n/static/70e4a7dbac16d962abb143ed15148892/2276a/software-with-these-features-yelowsoft.webp 400w,\n/static/70e4a7dbac16d962abb143ed15148892/cf465/software-with-these-features-yelowsoft.webp 800w,\n/static/70e4a7dbac16d962abb143ed15148892/cbd37/software-with-these-features-yelowsoft.webp 1200w,\n/static/70e4a7dbac16d962abb143ed15148892/64296/software-with-these-features-yelowsoft.webp 1600w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }), "\n", React.createElement(_components.p, null, "This thinking was true in the absence of SaaS-based ", React.createElement(_components.a, {
    href: "/blog/white-label-trusted-taxi-dispatch-software/"
  }, "white label taxi dispatch software"), ". These are the ready-to-use software which you can buy and use their expertise + technology to launch your application in 2 to 3 weeks max."), "\n", React.createElement(_components.p, null, "Several companies like Yelowsoft are providing ready-to-use software at just a subscription-like price. (as you subscribe to your Netflix account.)"), "\n", React.createElement(_components.p, null, "Make sure when you buy ready-to-use ", React.createElement(_components.a, {
    href: "/taxi-dispatch-platform/"
  }, "taxi dispatch platform"), ", it should have these core features:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Corporate"), "\n", React.createElement(_components.li, null, "Driver app"), "\n", React.createElement(_components.li, null, "Queue system"), "\n", React.createElement(_components.li, null, "Passenger ap"), "\n", React.createElement(_components.li, null, "Self-operating kiosk"), "\n", React.createElement(_components.li, null, "Dispatcher tablet app"), "\n", React.createElement(_components.li, null, "Admin panel/backend"), "\n", React.createElement(_components.li, null, "Business intelligence & reports"), "\n", React.createElement(_components.li, null, "eWallet for passengers & drivers"), "\n", React.createElement(_components.li, null, "Pricing configuration & zone price"), "\n", React.createElement(_components.li, null, "Manual or auto dispatch with a smart algorithm"), "\n"), "\n", React.createElement(_components.h2, {
    id: "stay-relevant-with-the-technology"
  }, "Stay relevant with the technology"), "\n", React.createElement(_components.p, null, "As the demand of the customer changes, in the same way, technology keeps changing. Choose the ", React.createElement(_components.a, {
    href: "/"
  }, "SaaS-based software"), " that is built with the latest and most reliable technology. The software should be flexible enough to compete with other software."), "\n", React.createElement(_components.p, null, "It means, your software should be able to accept the integration of 3rd party software. And it should be feature-rich enough to make your optimum tasks automated."), "\n", React.createElement(_components.p, null, "Your software should be able to compete with today’s market champions and the upcoming ones in the future in terms of technology."), "\n", React.createElement(_components.h2, {
    id: "focus-on-providing-great-service"
  }, "Focus on providing great service"), "\n", React.createElement(_components.p, null, "No one has won the market and customers' hearts with just a great product. Not even Apple and Nike. It is the combination of both great product + service = success."), "\n", React.createElement(_components.p, null, "In the same way, you alone would not succeed with just the best taxi or the software, you will have to learn to offer a great service using the small fleet taxi dispatch software."), "\n", React.createElement(_components.p, null, "If you master this skill, your competitors will start following you."), "\n", React.createElement(_components.h2, {
    id: "offer-nichespeciality-options"
  }, "Offer niche/speciality options"), "\n", React.createElement(_components.p, null, "Companies that value their name and know how to categorize their services and products are the ones who drive the most revenue."), "\n", React.createElement(_components.p, null, "You should be one of them. Categorize your taxi service. Offer special options to your customers like cars for children, cars for the disabled, and more. You can also offer special services for airport, corporate, and ride-hailing separately."), "\n", React.createElement(_components.p, null, "Give your passengers the power to choose what they need based on the number of passengers, luggage, luxury, price, distance, events and more. Categorizing this way can help you drive massive revenue."), "\n", React.createElement(_components.h2, {
    id: "get-insights-into-your-business"
  }, "Get insights into your business"), "\n", React.createElement(_components.p, null, "Big names do not make their business decisions on assumptions. They use business insights."), "\n", React.createElement(_components.p, null, "How? Well, they have the analytics in their software."), "\n", React.createElement(_components.p, null, "Go for the white label software that has this unique and advanced feature built in. This feature will help you make data-driven decisions."), "\n", React.createElement(_components.p, null, "You will be able to get insights into your business performance, customer behavior, driver, fleet, and every single aspect you can think of. You can organize this data, study it, analyze it and based on it, you can make highly result-driven decisions."), "\n", React.createElement(_components.p, null, "Decisions made using analytics are considered 99.9% success-driven. Make sure your software has this feature."), "\n", React.createElement(_components.h2, {
    id: "safety-and-security"
  }, "Safety and Security"), "\n", React.createElement(_components.p, null, "Be it you or your customer, everyone wants the assurance of safety and security. Taxi dispatch system enhances your passenger safety and security."), "\n", React.createElement(_components.p, null, "With the best cab booking app, your passengers can share their ride details with family or friends. The live location will be shared with them and the fleet can be tracked every second. This ensures high security."), "\n", React.createElement(_components.p, null, "Drivers are held accountable through the tracking system. In case of emergencies, dispatch systems can quickly locate the vehicle and assist the driver and passenger."), "\n", React.createElement(_components.h2, {
    id: "promote-local-rather-than-global"
  }, "Promote local, rather than global"), "\n", React.createElement(_components.p, null, "The road to success starts from your home. It means you should work hard in your locality first. Serve your local customers in such a way that they become your fans. They should feel proud of telling others that they use your taxi services."), "\n", React.createElement(_components.p, null, "Collect maximum feedback from them, have all of them on the internet and use them as a weapon to grow. Once you become a local brand, your next phase will be to expand it. And you can expand it from city to state to nation to across borders. But first, promote your brand locally."), "\n", React.createElement(_components.h2, {
    id: "implement-rewards-programs"
  }, "Implement rewards programs"), "\n", React.createElement(_components.p, null, "Uber and other popular brands keep introducing reward programs. They use it to attract new customers and retain the existing ones. It works like a magnet for them."), "\n", React.createElement(_components.p, null, "You can use it too. The ride-hailing software you use should be able to allow you to create reward programs as you want. Offer discounts, bonuses, loyalty programs, and other magnetic formulas to keep the cycle of profit moving."), "\n", React.createElement(_components.h2, {
    id: "conclusion"
  }, "Conclusion"), "\n", React.createElement(_components.p, null, "Taxi dispatch has revolutionized. If you want to be competitive in the market, drive more customers with profit along with growing your name as a brand, you must adapt to what your competitors have adopted. You will have to do what your customers want. They all are following the above-mentioned strategies only."), "\n", React.createElement(_components.p, null, "And now you know they want convenience which you can provide only with reliable software. The best thing is that you do not have to spend time, money, and resources on ", React.createElement(_components.a, {
    href: "/taxi-dispatch-software/"
  }, "taxi dispatch software development"), " from scratch."), "\n", React.createElement(_components.p, null, "You can get it ready-made from the market. You can get white-label taxi dispatch software which you can use to launch your branded app in days and start offering Uber-like taxi dispatch services right from launching the app."), "\n", React.createElement(_components.p, null, "The strategy includes software as the core weapon to succeed in the taxi industry. Leverage the expertise of companies offering ready-made software and start growing your business."), "\n", React.createElement(_components.a, {
    href: "/contact"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 25%; position: relative; bottom: 0; left: 0; background-image: url('data:image/webp;base64,UklGRmQAAABXRUJQVlA4IFgAAACQAwCdASoUAAUAPtFUpEuoJKOhsAgBABoJQBadBD4Nz28m9ScgAP7rNX1cQzSiD9SjCZo4uPrs6I8un5WNGcR61/Ko0wVmGMM/oexN3LbfWIxKxo0tAAAA'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"saas-taxi-dispatch-software\"\n        title=\"\"\n        data-src=\"/static/2f8b352a004fd9c6311c679f3fd32a5b/cf465/saas-taxi-dispatch-software.webp\"\n        data-srcset=\"/static/2f8b352a004fd9c6311c679f3fd32a5b/a5e6d/saas-taxi-dispatch-software.webp 200w,\n/static/2f8b352a004fd9c6311c679f3fd32a5b/2276a/saas-taxi-dispatch-software.webp 400w,\n/static/2f8b352a004fd9c6311c679f3fd32a5b/cf465/saas-taxi-dispatch-software.webp 800w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.h2, {
    id: "faqs"
  }, "FAQ's"), "\n", React.createElement(Accordion, {
    defaultActiveKey: "0"
  }, React.createElement(Accordion.Item, {
    eventKey: "0"
  }, React.createElement(Accordion.Header, null, "1. What is the Taxi Dispatch software?"), React.createElement(Accordion.Body, null, React.createElement("p", {
    className: "mb-0"
  }, "Taxi dispatch software is a technology solution that helps taxi and ride-hailing companies automate and manage their fleet operations from a single screen. It helps managers to assign ride requests to drivers, ", React.createElement(_components.a, {
    href: "/blog/role-of-gps-tracking-taxi-business/"
  }, "track vehicles in real time"), ", and provide reporting on metrics like driver performance and customer usage. This technology is essential for running an efficient, profitable taxi business today."))), React.createElement(Accordion.Item, {
    eventKey: "1"
  }, React.createElement(Accordion.Header, null, "2. What is the importance of taxi dispatch software?"), React.createElement(Accordion.Body, null, React.createElement("p", {
    className: "mb-0"
  }, "The taxi dispatch software is crucial for managing a smooth taxi dispatch and ", React.createElement(_components.a, {
    href: "/ride-hailing-software"
  }, "ride-hailing service"), ". It allows taxi businesses to match customer ride requests with available drivers nearby efficiently. The software also provides real-time tracking of all vehicles to improve safety and service. Additionally, the software helps in automating business, streamlining operations, enhancing customer satisfaction and ultimately increasing profit."))), React.createElement(Accordion.Item, {
    eventKey: "2"
  }, React.createElement(Accordion.Header, null, "3. How can I take my taxi dispatch business online? "), React.createElement(Accordion.Body, null, React.createElement("p", {
    className: "mb-0"
  }, "Taking your taxi business online is easy these days with off-the-shelf dispatch solutions. You can either buy a readymade taxi dispatch software platform or work with a developer to develop a taxi dispatch software from scratch as per your specific business needs."))), React.createElement(Accordion.Item, {
    eventKey: "3"
  }, React.createElement(Accordion.Header, null, "4. Shall I buy or develop taxi dispatch software?"), React.createElement(Accordion.Body, null, React.createElement("p", {
    className: "mb-0"
  }, "Buying an off-the-shelf product is faster and more affordable, while custom development provides more flexibility but is 50 times more expensive than the readymade taxi dispatch software. Evaluate your budget, business goals, and growth plans to decide what works best for you."))), React.createElement(Accordion.Item, {
    eventKey: "4"
  }, React.createElement(Accordion.Header, null, "5. What features shall I keep in mind when opting for a taxi dispatch solution?"), React.createElement(Accordion.Body, null, React.createElement("p", {
    className: "mb-0"
  }, "When you decide on a taxi dispatch solution, features you should keep in mind are driver and vehicle management functionality, ride request assignment and tracking, real-time GPS monitoring, fare calculations, payment processing, analytics and reporting, customer booking mobile apps and driver mobile apps. Prioritize must-have features vs. nice-to-haves to find the best solution for your budget and needs."))), React.createElement(Accordion.Item, {
    eventKey: "5"
  }, React.createElement(Accordion.Header, null, "6. What technology should I consider when selecting a taxi dispatch platform?"), React.createElement(Accordion.Body, null, React.createElement("p", {
    className: "mb-0"
  }, "When choosing a technology solution, look for a cloud-based platform that leverages modern tools like AI, machine learning, and big data analytics. This will provide the most automation, efficiency, and actionable insights. Also, ensure there are well-designed mobile apps for a seamless customer and driver experience."))), React.createElement(Accordion.Item, {
    eventKey: "6"
  }, React.createElement(Accordion.Header, null, "7. How do reports and analytics features help in growing the dispatch business?"), React.createElement(Accordion.Body, null, React.createElement("p", {
    className: "mb-0"
  }, "Robust analytics and insightful reporting are pivotal for improving operations and revenues. Key metrics like driver performance, route/demand analysis, peak booking times/locations, customer usage patterns and more allow taxi businesses to fine-tune their business. Fleet owners can utilize this data to optimize driver shifts, adjust fleet sizes, improve customer experience, implement promotional campaigns, expand service areas and generally make smarter decisions to profitably scale the business over time.")))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
